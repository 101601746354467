import styled from "@emotion/styled";
import React from "react";
import { WithChildren } from "../../common/utils/types";

interface Props {
  image?: string;
  position?: string;
  size?: string;
  objectPosition?: string;
  color?: string;
  objectFit?: string;
}
export const CLASSNAME_BG_IMAGE = "__background-image";
export const Base = styled.div<Props>`
  &.${CLASSNAME_BG_IMAGE}{
    ${({ image }) => image && `background-image: url('${image}');`} 
    background-position: ${({ position }) => position || "top center"};
    position: ${({ objectPosition }) => objectPosition || "relative"};
    object-fit: ${({ objectFit }) => objectFit || "cover"};
    background-size: ${({ size }) => size || "auto 100%"};
    background-color: ${({ color }) => color || "transparent"};
  }
`;
export const Background: React.FC<Props & WithChildren & any> = props => (
  <Base {...props} className={`${props.className} ${CLASSNAME_BG_IMAGE}`} />
);
