import { RefObject, useRef } from "react";

export type NavigationItem = "home" | "ventures" | "about" | "contact";
export interface NavigationList {
  [id: string]: RefObject<HTMLDivElement>;
}
export const useNavigation = () => {
  const refs: NavigationList = {
    home: useRef<HTMLDivElement>(null),
    ventures: useRef<HTMLDivElement>(null),
    about: useRef<HTMLDivElement>(null),
    contact: useRef<HTMLDivElement>(null),
    // careers: useRef<HTMLDivElement>(null),
  };
  const onScrollTo = (element: NavigationItem) => {
    const ref = refs[element];

    if (!!ref && !!ref.current) {
      const element: HTMLDivElement = ref.current;
      const offset = -0;

      setTimeout(() => {
        const [top, behavior]: [number, "smooth" | "auto"] = element
          ? [element.offsetTop + offset, "smooth"]
          : [0, "auto"];

        window.scrollTo({
          behavior,
          top,
        });
      }, 100);
    }
    return null;
  };

  return {
    onScrollTo,
    refs,
  };
};
