import { GridContent, GridItem, H3 } from "../SharedStyling";
import { Background } from "../../common/components/Background";
import * as React from "react";
import styled from "styled-components";
import { NavigationItem } from "../../common/hooks/useNavigation";

const Text = styled.p.attrs({ className: "__serif" })`
  font-size: 1.25rem;

  @media all and (max-width: 1280px) {
    font-size: 1.15rem;
  }
`;
const Inner = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  flex-grow: 1;
`;

interface VentureGridItem {
  title: string;
  text: string;
  image: string;
  link: string;
  scrollTo?: NavigationItem;
  imagePosition?: string;
  exit?: number;
}
const data: VentureGridItem[] = [
  {
    title: "Lynchburg Collective",
    text: "A tech savvy consultancy that makes big corporations feel like startups.",
    image: "lynchburg.png",
    link: "https://lynchburgcollective.com",
  },
  {
    title: "Hellström",
    text: "A liquid maker that creates outstanding spirits with a taste of Swedish wilderness.",
    image: "hellstrom.jpg",
    link: "https://www.hellstromgin.com/",
  },
  {
    title: "Scorey",
    text: "An app that makes your racket game feel like a world championship.",
    image: "scorey.jpg",
    link: "https://scoreyapp.com",
    exit: 1,
  },
  {
    title: "Bokio",
    text: "An AI software that turns accounting into a walk in the park.",
    image: "bokio.png",
    link: "https://bokio.se",
    exit: 2,
  },
  {
    title: "Mensch",
    text:
      "An HR interim firm that believes people development and business development is the same thing.",
    image: "mensch.png",
    link: "https://mensch.se",
  },
  {
    title: "More People to come",
    text: "Get in touch.",
    image: "nextventure.png",
    link: "#",
    scrollTo: "contact",
    imagePosition: "top center",
  },
];

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;

  @media all and (max-width: 699px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const Exited = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  z-index: 3;
  padding: 12px 24px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  text-transform: uppercase;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
`;

interface Props {
  onScrollTo: (element: NavigationItem) => void;
}
export const VentureGrid: React.FC<Props> = ({ onScrollTo }) => (
  <Wrapper>
    {data.map(
      ({ title, text, exit, image, imagePosition = "center center", link, scrollTo }, i) => (
        <GridItem key={i} onClick={scrollTo ? () => onScrollTo(scrollTo) : () => false}>
          <GridContent href={link} target={scrollTo ? "_self" : "_blank"}>
            <Background
              className={"__3-1"}
              size={"cover"}
              position={imagePosition}
              image={`/assets/images/ventures/${image}`}
            />
            <Inner>
              <H3>{title}</H3>
              <Text>{text}</Text>
              {exit && exit === 1 && <Exited>Exited</Exited>}
              {exit && exit === 2 && <Exited>Part Exited</Exited>}
            </Inner>
          </GridContent>
        </GridItem>
      ),
    )}
  </Wrapper>
);
