import * as React from "react";
import { Head } from "../common/navigation/navigationUtils";
import { Colors } from "../common/utils/style";
import { RouteDefinition, WithChildren } from "../common/utils/types";
import { Wrapper } from "../layout/Wrapper";
import { HomeSection } from "../components/sections/HomeSection";
import { HeaderSection } from "../components/sections/HeaderSection";
import { VentureSection } from "../components/sections/VentureSection";
import { AboutSection } from "../components/sections/AboutSection";
import { ContactSection } from "../components/sections/ContactSection";
import { useNavigation } from "../common/hooks/useNavigation";

const Route: RouteDefinition = {
  meta: {
    accentColor: Colors.black,
    description: null,
  } as Head,
};
export const Home: React.FC<WithChildren> = () => {
  const {
    onScrollTo,
    refs: { home, ventures, about, contact },
  } = useNavigation();
  return (
    <Wrapper route={Route}>
      <HeaderSection onScrollTo={onScrollTo} />
      <HomeSection sectionRef={home} />
      <VentureSection onScrollTo={onScrollTo} sectionRef={ventures} />
      <AboutSection sectionRef={about} />
      <ContactSection sectionRef={contact} />
    </Wrapper>
  );
};

export default Home;
