import { Content, H1, Section, WideContainer } from "../SharedStyling";
import React, { useRef, useEffect, RefObject } from "react";
import { Colors } from "../../common/utils/style";
import { useTypeIt } from "../../common/hooks/useTypeIt";

export const TYPEIT_PAUSE = 400;

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
}
export const HomeSection: React.FC<Props> = ({ sectionRef }) => {
  const ref = useRef(null);
  const { typeIt } = useTypeIt(ref, {
    speed: 50, // 35 40,
    startDelay: 900,
    deleteSpeed: 55,
  });

  useEffect(() => {
    if (!!typeIt) {
      typeIt
        .type("We do not invest in technology.<br />")
        .pause(TYPEIT_PAUSE)
        .type("We do not invest in innovation.<br />")
        .pause(TYPEIT_PAUSE)
        .type("We do not invest in any brilliant ideas whatsoever.<br />")
        .pause(TYPEIT_PAUSE * 3)
        .type("We invest in the people behi")
        .pause(TYPEIT_PAUSE * 1.5)
        .delete(11)
        .type(
          `<strong>people</strong> behind them<strong style='color:${Colors.Yellow}'>.</strong>`,
        )
        .go();
    }
  }, [typeIt]);

  return (
    <Section innerRef={sectionRef}>
      <Content>
        <WideContainer>
          <H1>
            <div ref={ref} />
          </H1>
        </WideContainer>
      </Content>
    </Section>
  );
};
