import * as React from "react";
import { Content, H2, NarrowContainer, Paragraph, Section } from "../SharedStyling";
import { Background } from "../../common/components/Background";
import { useTypeItForHeading } from "../../common/hooks/useTypeItForHeading";
import { RefObject } from "react";
import styled from "@emotion/styled";

const ExtendedContent = styled(Content)`
  justify-content: flex-end;
`;
const ExtendedNarrowContainer = styled(NarrowContainer)`
  margin: 0 auto;

  @media all and (min-width: 1280px) {
    margin-bottom: 160px;
  }
  @media all and (max-width: 1279px) and (min-width: 1121px) {
    margin-bottom: 120px;
  }
  @media all and (max-width: 1120px) and (min-width: 768px) {
    margin-bottom: 80px;
  }
  @media all and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;
const BlockScopeSpan = styled.span`
  display: inline-block;
`;

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
}
export const AboutSection: React.FC<Props> = ({ sectionRef }) => {
  const { ref } = useTypeItForHeading("About us");
  return (
    <>
      <div ref={sectionRef} />
      <Background image={"/assets/images/bg.jpg"} position={"bottom center"} size={"100% auto"}>
        <Section>
          <ExtendedContent>
            <ExtendedNarrowContainer>
              <H2 color={"white"} innerRef={ref} />
              <Paragraph color={"white"}>
                Younicon Studio is an early stage venture capital company founded in 2017.{" "}
                <BlockScopeSpan>Co-founding</BlockScopeSpan> partners Johan Düfke and Kalle Lindvall
                have over three decades of combined experience building and scaling companies.
              </Paragraph>
            </ExtendedNarrowContainer>
          </ExtendedContent>
        </Section>
      </Background>
    </>
  );
};
