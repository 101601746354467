import * as React from "react";
import { Helmet as HelmetComponent } from "react-helmet";
import { Colors } from "../utils/style";
import { RouteDefinition } from "../utils/types";

interface Props {
  route: RouteDefinition;
}
interface Config {
  baseDesc: string;
  baseKeywords: string[];
  baseUrl: string;
  baseTitle: string;
}
interface Params {
  accentColor: string;
  bodyClass: string;
  description: string;
  image: string | null;
  keywords: string;
  siteName: string;
  slug: string;
  title: string;
}

const setupParams = (config: Config, route: RouteDefinition): Params => ({
  accentColor: (route && route.meta && route.meta.accentColor) || Colors.pink,
  bodyClass: (route && route.meta && route.meta.theme) || "",
  description: (route && route.meta && route.meta.description) || config.baseDesc,
  image: (route && route.meta && route.meta.image) || null,
  keywords: `${config.baseKeywords.join(",")},${
    route && route.meta && route.meta.keywords && route.meta.keywords.length > 0
      ? route.meta.keywords.join(",")
      : ""
  }`,
  siteName: config.baseTitle,
  slug: config.baseUrl,
  title:
    route && route.meta && route.meta.title
      ? `${config.baseTitle} - ${route.meta.title}`
      : config.baseTitle,
});
export const Helmet: React.FC<Props> = ({ route }) => {
  const {
    accentColor,
    bodyClass,
    description,
    image,
    keywords,
    siteName,
    slug,
    title,
  } = setupParams(
    {
      baseDesc:
        "Younicon Studio is an early stage venture capital company founded in 2017. Co-founding partners Johan Düfke and Kalle Lindvall have over three decades of combined experience building and scaling companies.",
      baseKeywords: ["younicon", "studio"],
      baseTitle: "Younicon Studio",
      baseUrl: "https://youniconstudio.com",
    },
    route,
  );
  return (
    <HelmetComponent>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />

      <title>{title}</title>

      <link rel="apple-touch-icon" sizes="180x180" href={`/assets/favicon/apple-touch-icon.png`} />
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/assets/manifest.json" />
      <link rel="mask-icon" href="/assets/favicon/safari-pinned-tab.svg" color="#fedc56" />
      <meta name="apple-mobile-web-app-title" content="Younicon Studio" />
      <meta name="application-name" content="Younicon Studio" />
      <meta name="msapplication-TileColor" content={accentColor} />
      <meta name="msapplication-TileImage" content="/assets/favicon/ms-icon-144x144.png" />
      <meta name="theme-color" content={accentColor} />

      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={slug} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={slug} />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:site_name" content={siteName} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />

      <link
        rel="stylesheet"
        media="all"
        href="https://fonts.googleapis.com/css?family=Heebo:400,700|Rufina&display=swap"
      />

      <body className={bodyClass} />
    </HelmetComponent>
  );
};
/*
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-136869859-1" /></script>
      <script>window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', overall Date()); gtag('config', 'UA-136869859-1');</script>
*/
