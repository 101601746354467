import * as React from "react";
import { Content, H2, Paragraph, Section, WideContainer } from "../SharedStyling";
import { VentureGrid } from "../parts/VentureGrid";
import styled from "@emotion/styled";
import { useTypeItForHeading } from "../../common/hooks/useTypeItForHeading";
import { RefObject } from "react";
import { NavigationItem } from "../../common/hooks/useNavigation";

const Punch = styled(Paragraph)`
  color: #494949;
  font-weight: 400;
  text-align: left;
  margin-bottom: 2rem !important;
  font-weight: 900;
  margin-top: 60px;

  @media all and (max-width: 1200px) {
    margin-top: 40px;
  }
  @media all and (max-width: 767px) {
    margin-top: 20px;
  }
`;
const Container = styled.div`
  padding-left: 10px;

  @media all and (max-width: 920px) {
    width: 85%;
  }
  @media all and (max-width: 767px) {
    width: 100%;
  }
`;
const Buffer = styled.div`
  height: 30vh;

  @media all and (max-width: 920px) {
    height: 20vh;
  }
  @media all and (max-width: 767px) {
    height: 10vh;
  }
`;

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
  onScrollTo: (element: NavigationItem) => void;
}
export const VentureSection: React.FC<Props> = ({ onScrollTo, sectionRef }) => {
  const { ref } = useTypeItForHeading("Ventures");
  return (
    <Section innerRef={sectionRef}>
      <Content>
        <WideContainer>
          <Container>
            <H2 innerRef={ref} />
            <Paragraph>
              Our ventures all have something in common: People that have the ability to disrupt old
              and dusty business models with creativity and a completely unapologetic attitude.
            </Paragraph>
            <Punch>We're currently proud co-owners of</Punch>
          </Container>
          <VentureGrid onScrollTo={onScrollTo} />
        </WideContainer>
      </Content>
      <Buffer />
    </Section>
  );
};
