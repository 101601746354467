import * as React from "react";
import {
  Content,
  H2,
  Name,
  Paragraph,
  Section,
  NarrowContainer,
  ContactParagraph,
} from "../SharedStyling";
import { Box } from "../../common/components/Box";
import { useTypeItForHeading } from "../../common/hooks/useTypeItForHeading";
import { RefObject } from "react";
import styled from "styled-components";

const sharedStyle = `
  line-height: 1.45em;
  font-size: 1.25rem;
  
  @media all and (min-width: 1480px) {
    font-size: 1.55rem;
  }
`;
const Description = styled(Paragraph)`
  ${sharedStyle}
  margin: 30px 0 20px;

  @media all and (min-width: 920px) {
    height: 5.8em; // 4 * 1.45
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
`;
const ContactDetail = styled.a.attrs({ className: "__serif" })`
  ${sharedStyle}
  display: block;
  margin-bottom: 0;
`;
const Spacer = styled.div`
  min-width: 50px;
  height: 5px;

  @media all and (max-width: 920px) {
    height: 60px;
  }
`;
const Inner = styled.div`
  max-width: 520px;
  padding: 5px;
`;
const Wrapper = styled(Box)`
  margin: 100px 0;
  display: flex;
  flex-direction: row;

  @media all and (max-width: 920px) {
    flex-direction: column !important;
    margin: 50px 0;
  }
`;

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
}
export const ContactSection: React.FC<Props> = ({ sectionRef }) => {
  const { ref } = useTypeItForHeading("Get in touch");
  return (
    <Section innerRef={sectionRef}>
      <Content>
        <NarrowContainer>
          <H2 innerRef={ref} />
          <Wrapper row={true} justifyContent={"space-between"}>
            <Contact
              name={"Johan Düfke"}
              description={
                "Previous grunge drummer, sealife freak and mountain hiker with background in sales, marketing and economics."
              }
              email={"johan@youniconstudio.com"}
              phone={"+46(0) 733 81 05 26"}
              linkedIn={"https://www.linkedin.com/in/johandufke/"}
            />
            <Spacer />
            <Contact
              name={"Kalle Lindvall"}
              description={
                "Former jazz drummer, passionate sailer and architect geek with background in psychology and organization development."
              }
              email={"kalle@youniconstudio.com"}
              phone={"+46(0) 723 11 88 44"}
              linkedIn={"https://www.linkedin.com/in/kallelindvall/"}
            />
          </Wrapper>
        </NarrowContainer>
        <ContactParagraph style={{ fontSize: "1rem", margin: "30px 0" }}>
          <a
            href={"https://www.google.com/maps/place/Kungsportsavenyen+21,+411+36+G%C3%B6teborg/"}
            target={"_blank"}
          >
            Kungsportsavenyen 21, Gothenburg, Sweden
          </a>
          . But we consider the world our local market.
        </ContactParagraph>
      </Content>
    </Section>
  );
};

interface ContactProps {
  name: string;
  description: string;
  email: string;
  phone: string;
  linkedIn: string;
}
const Contact: React.FC<ContactProps> = ({ name, description, email, phone, linkedIn }) => (
  <Inner>
    <Name>{name}</Name>
    <Description>{description}</Description>
    <ContactDetail href={`mailto:${email}`}>{email}</ContactDetail>
    <ContactDetail href={`tel:${washPhone(phone)}`}>{phone}</ContactDetail>
    <ContactDetail target={"_blank"} href={linkedIn}>
      LinkedIn
    </ContactDetail>
  </Inner>
);
const washPhone = (phone: string) =>
  phone
    .replace("(0)", "")
    .replace(" ", "")
    .replace(" ", "")
    .replace(" ", "")
    .replace(" ", "")
    .replace(" ", "");
