import React, { useEffect, useState } from "react";
// @ts-ignore
import TypeIt from "typeit";

const isInViewport = (element: HTMLDivElement, topOffset?: number): boolean => {
  const offset: number = topOffset || 0;
  if (!element) return false;
  const top = element.getBoundingClientRect().top;

  return top + offset >= 0 && top - offset <= window.innerHeight;
};

export const useTypeIt = (ref: React.RefObject<HTMLDivElement>, typeItConfig?: Object) => {
  const [init, setInit] = useState(false);
  const [doActivate, setDoActivate] = useState(false);
  const [typeIt, setTypeIt] = useState<any>(null);

  useEffect(() => {
    if (!!ref.current) {
      const element: HTMLDivElement = ref.current;

      if (!init) {
        setTypeIt(
          new TypeIt(ref.current, {
            afterComplete: () => {
              setTimeout(() => {
                element.classList.add("ti-finished");
              }, 1000);
            },
            ...(typeItConfig || {}),
          }),
        );

        setInit(true);
      }
    }
    const handleScroll = () => {
      if (!!ref.current && !doActivate) {
        const element: HTMLDivElement = ref.current;

        setDoActivate(isInViewport(element));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref.current]);

  return {
    typeIt,
    isReady: init && doActivate,
    initialize: init,
    doActivate,
  };
};
