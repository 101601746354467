import { Header, HeaderContent } from "../SharedStyling";
import * as React from "react";
import { useRef } from "react";
import { useScrollPosition } from "../../common/hooks/useScrollPosition";
import { Colors } from "../../common/utils/style";
import styled from "@emotion/styled";
import { NavigationItem } from "../../common/hooks/useNavigation";

const dotSize = 46;
const Logo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  img {
    width: 250px;

    @media all and (max-width: 767px) {
      width: 175px;
    }
    @media all and (max-width: 565px) {
      width: 125px;
    }
  }
  span {
    color: ${Colors.Yellow};
    font-size: ${dotSize}px;
    height: ${dotSize}px;
    line-height: ${dotSize}px;
    font-weight: bold;
    position: absolute;
    bottom: -8px;
    right: -12px;
    display: block;
  }
`;

const getMenu = (): Array<{ name: NavigationItem; label: string }> => [
  {
    name: "home",
    label: "Home",
  },
  {
    name: "ventures",
    label: "Ventures",
  },
  {
    name: "about",
    label: "About",
  },
  {
    name: "contact",
    label: "Contact",
  },
];

interface Props {
  onScrollTo: (element: NavigationItem) => void;
}
export const HeaderSection: React.FC<Props> = ({ onScrollTo }) => {
  const ref = useRef(null);
  const {
    position: { top },
    direction,
  } = useScrollPosition(ref);
  return (
    <>
      <div ref={ref} />
      <Header small={top < -10} show={direction === "up"}>
        <HeaderContent>
          <Logo>
            <img
              src="https://uploads-ssl.webflow.com/5dc40d0f51595b4b4aaa3b8d/5dc40db2429c1aeeb3fa5c4a_YouniconLogo_Horizontal.svg"
              width="250"
              alt=""
              className="image"
            />
            <span>.</span>
          </Logo>
          <ul>
            {getMenu().map(({ name, label }) => (
              <li key={name}>
                <a onClick={() => onScrollTo(name)}>{label}</a>
              </li>
            ))}
          </ul>
        </HeaderContent>
      </Header>
    </>
  );
};
