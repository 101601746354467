import { useEffect, useRef } from "react";
import { useTypeIt } from "./useTypeIt";

export const useTypeItForHeading = (text: string) => {
  const ref = useRef(null);
  const { typeIt, isReady } = useTypeIt(ref, {
    speed: 200,
    startDelay: 900,
  });

  useEffect(() => {
    if (!!typeIt && !!isReady) {
      typeIt.type(text).go();
    }
  }, [typeIt, isReady]);

  return {
    ref,
  };
};
