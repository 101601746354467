export const Colors = {
  black: "#0B3A53", // "#002d40", /// "#280E19",
  darkgreen: "#052427", // (5, 36, 39
  green: "#013034",
  grey: "#767778",
  lightgrey: "#e7e8e9",
  pink: "#ff7a66",
  sand: "#faf7f2",
  teal: "#198e8f",
  white: "#faf7f2", // "#ffffff", // "#ECECEC", //rgb(236,236,236)",

  Background: "#f6f6f6",
  Grey2: "#a0a0a0",
  White: "#ffffff",
  Yellow: "#fedc56", // "#E8A236",
};
