import styled from "styled-components";
import { Colors } from "../common/utils/style";

export const Section = styled.div`
  width: 100%;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0px;
  width: 80%;
  margin: auto;
  max-width: 1430px;
`;
export const WideContainer = styled.div`
  width: 100%;
  margin: auto;
  height: inherit;
  max-width: 1430px;
`;
export const NarrowContainer = styled(WideContainer)`
  max-width: 1024px;
`;
export const H1 = styled("h1")`
  span.ti-cursor {
    color: ${Colors.Yellow} !important;
  }

  @media all and (max-width: 890px) {
    font-size: 2.7rem;
  }
  @media all and (max-width: 767px) {
    font-size: 2.25rem;
  }
  @media all and (max-width: 625px) {
    font-size: 1.5rem;
  }
  @media all and (max-width: 554px) {
    font-size: 1.15rem;
  }
  @media all and (max-height: 560px) and (orientation: landscape) {
    padding-top: 60px; /* 100 - 40 */
  }
  @media all and (max-height: 480px) and (orientation: landscape) {
    padding-top: 35px; /* 75 - 40 */
  }
`;
export const H2 = styled("h2")<{ color?: string }>`
  color: ${({ color }) => color || "inherit"};

  @media all and (min-width: 1480px) {
    font-size: 5.25rem;
  }
  @media all and (max-width: 1479px) {
    font-size: 4.75rem;
  }
  @media all and (max-width: 1279px) {
    font-size: 3.75rem;
  }
  @media all and (max-width: 767px) {
    font-size: 2.25rem;
  }
`;
export const H3 = styled("h3").attrs({ className: "__serif" })`
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0;

  @media all and (max-width: 1280px) {
    font-size: 1.8rem;
  }
`;
export const PBase = `
    font-size: 1rem;
    line-height: 1.45em;
    text-align: left;
    
    @media all and (min-width: 1480px) {
      font-size: 2.05rem;
    }
    @media all and (max-width: 1479px) and (min-width: 768px) {
      font-size: 1.85rem;
    }
    @media all and (max-width: 767px) {
      font-size: 1.5rem;
    }
    @media all and (max-width: 565px) {
      font-size: 1.25rem;
    }
`;
export const Paragraph = styled.p.attrs({ className: "__serif" })<{ color?: string }>`
  color: ${({ color }) => color || "inherit"};
  ${PBase}
`;
export const ContactParagraph = styled.p.attrs({ className: "__serif" })<{ color?: string }>`
  color: ${({ color }) => color || "inherit"};
  ${PBase};
  font-size: 1.25rem;
  margin: 40px 0;
  font-weight: 400;
  text-align: center;
`;
const fixedWidth = (w: number): string => `width: ${w}%;max-width: ${w}%;min-width: ${w}%;`;
export const GridItem = styled.div`
  ${fixedWidth(100 / 3)};
  display: flex;
  flex-grow: 1;

  &:hover {
    & > a {
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
      transform: scale(1.05);
    }
  }

  @media all and (max-width: 1080px) {
    ${fixedWidth(50)};
  }
  @media all and (max-width: 1499px) and (min-width: 1310px) {
    &:before {
      padding-top: 115%; // custom ratio
    }
  }
  @media all and (max-width: 1309px) and (min-width: 1081px) {
    &:before {
      padding-top: 133.33333%; // 3-4
    }
  }
  @media all and (max-width: 949px) and (min-width: 833px) {
    &:before {
      padding-top: 115%; // custom ratio
    }
  }
  @media all and (max-width: 832px) and (min-width: 700px) {
    &:before {
      padding-top: 133.33333%; // 3-4
    }
  }
  @media all and (max-width: 699px) {
    ${fixedWidth(100)};
    max-width: 500px;
    margin: auto;
    float: none;
    height: auto;

    &:before {
      display: none;
    }
  }
`;
export const GridContent = styled.a.attrs({ className: "content" })`
  background: ${Colors.White};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 10px;

  &.content {
    @media all and (max-width: 699px) {
      position: relative;
    }
  }
`;
export const Name = styled(H3)`
  display: block;

  font-size: 1.8rem;
  letter-spacing: 0;

  @media all and (max-width: 1280px) {
    font-size: 1.6rem;
  }
`;
export const Header = styled.div<{ small: boolean; show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 150px;
  ${({ small }) => (small ? `box-shadow: rgba(0,0,0,0.025) 0 2px 4px 0;` : "")};
  background: ${({ small }) => (small ? Colors.Background : "transparent")};
  transition: height: 0.3s, color 0.3s 0.3s, box-shadow 0.3s 0.6s;
  transform: ${({ small, show }) => (small && !show ? "translateY(-80px)" : "translateY(0)")};
  
  * {
    transition: all 0s;
  }
  @media all and (min-width: 768px) {
    ${({ small }) => (small ? `height: 50px !important;` : "")}
  }
  @media all and (max-width: 767px) {
    ${({ small }) => (small ? `height: 65px !important;` : "")}
  }
  @media all and (max-height: 560px) {
    height: 100px;
  }
  @media all and (max-height: 480px) {
    height: 75px;
  }
`;
export const HeaderContent = styled(Content)`
  min-height: 0 !important;
  height: inherit;
  padding: 10px 0;

  justify-content: space-between;
  flex-direction: row;
  align-items: center;

  ul {
    list-style: none;
    margin: 0;

    li {
      position: relative;
      display: inline-block;
      vertical-align: top;
      padding: 5px 20px;
      text-align: left;
      margin: auto;

      @media all and (max-width: 920px) {
        padding: 5px 10px;
      }
      a {
        font-weight: 700;
        letter-spacing: 3px;
        text-transform: uppercase;
        text-decoration: none;
        color: #222222;
        cursor: pointer;
        font-size: 0.75rem;
        line-height: 20px;

        &:after {
          display: none;
        }
      }
      &:hover {
        transform: scale(1.15);
        a {
          color: ${Colors.Yellow} !important;
        }
      }
    }
  }
  @media all and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;

    ul {
      margin: 5px auto 0;
    }
  }
  @media all and (max-width: 565px) {
    width: 100% !important;

    ul li {
      padding: 5px 5px;
    }
  }
`;
