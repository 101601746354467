import * as React from "react";
import { Helmet } from "../common/components/Helmet";
import { RouteDefinition, WithChildren } from "../common/utils/types";

export const Wrapper: React.FC<WithChildren & { route: RouteDefinition }> = ({
  children,
  route,
}) => {
  return (
    <>
      <Helmet route={route} />
      {children}
    </>
  );
};
