import * as React from "react";
import { useEffect, useState } from "react";
import { throttle } from "lodash";

interface WindowScrollPosition {
  left: number;
  leftMax: number;
  top: number;
  topMax: number;
}

interface WindowScrollPositionOptions {
  passive?: boolean;
  throttle?: number;
}

const getPosition = (element: HTMLElement | null): WindowScrollPosition => {
  if (!element || !element.getBoundingClientRect) {
    return {
      left: 0,
      leftMax: Number.MAX_VALUE,
      top: 0,
      topMax: Number.MAX_VALUE,
    };
  }
  const { x, y } = element.getBoundingClientRect() as DOMRect;
  return {
    left: x,
    leftMax: element.scrollWidth - element.offsetWidth,
    top: y,
    topMax: element.scrollHeight - element.offsetHeight,
  };
};

const defaultOptions: WindowScrollPositionOptions = {
  throttle: 100,
};

export const useScrollPosition = (
  ref: React.RefObject<any>,
  options?: WindowScrollPositionOptions,
) => {
  const opts: WindowScrollPositionOptions = { ...defaultOptions, ...options };

  const [direction, setDirection] = useState<"up" | "down">("up");
  const [y, setY] = useState<number>(0);
  const [position, setPosition] = useState<WindowScrollPosition>(getPosition(ref.current));

  useEffect(() => {
    const { top: newY } = position;

    if (newY < y && direction === "up") {
      setDirection("down");
    } else if (newY > y && direction === "down") {
      setDirection("up");
    }

    setPosition(position);
    setY(newY);
  }, [position]);

  // @ts-ignore
  useEffect(() => {
    if (ref.current) {
      const element = ref.current;

      setPosition(getPosition(element));

      const handleScroll = throttle(() => {
        setPosition(getPosition(element));
      }, opts.throttle);

      window.addEventListener("resize", handleScroll);
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      };
    }
  }, [ref.current]);

  return {
    direction,
    position,
  };
};
